import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
 } from "react-router-dom";
import React, {Fragment, useState, useEffect} from "react";
import FileBrowser from "./views/FileBrowser";
import Login from "./views/Login";
import RegUser from "./views/RegUser";
import MyPage from "./views/MyPage";
import MyWorkouts from "./views/MyWorkouts";
import CreateSession from "./views/CreateSession";
import AddStation from "./views/AddStation";
import ManageSession from "./views/ManageSession"
import Banner from "./components/Banner";
import NavBar from "./components/NavBar";
import AdminDashboard from "./views/AdminDashboard";
import UserSettings from "./views/UserSettings";
import ModifyUser from "./views/ModifyUser";
import DeleteUser from "./views/DeleteUser";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function App() {
  const [authenticatedUserRole, setAuthenticatedUserRole] = useState(0);
  
  const [exercise, setExercise] = useState({
    session: "",
    title: "",
    description: "",
    videoPath: "Ingen video vald",
  });

  const setAuth = (role) => {
    try {
      const roleInt = parseInt(role);
      setAuthenticatedUserRole(roleInt);
    }
    catch (err) {
      setAuthenticatedUserRole(0);
    }
  };

  async function isAuth() {
    // If localStorage is empty just exit quietly
    if (!localStorage.getItem("token")) { return };
    try {
      const response = await fetch(`${API_URL}/auth/is-verified`, {
        method: "GET",
        headers: {token: localStorage.token}
      });

      const role = await response.json();

      setAuth(role);
    } catch (err) {
      
    }
 }  

 useEffect(() => {
  isAuth();
  },[])

  return (
    <Fragment>
      <div className="flex flex-col h-screen">
        <Router>
        <Banner loggedIn={authenticatedUserRole}/>
          <div className="flex-1 overflow-y-auto">
          <Routes>
            <Route path="/" element={authenticatedUserRole ? (<MyPage userRole={authenticatedUserRole}/>) : (<Navigate replace to="/login" />)}/>
            <Route path="/login" element={!authenticatedUserRole ? (<Login setAuth={setAuth}/>) : (<Navigate replace to="/" />)} />
            <Route path="/admin-dashboard" element={(authenticatedUserRole === 1 || authenticatedUserRole === 2) ? (<AdminDashboard userRole={authenticatedUserRole}/>) : (<Navigate replace to="/mypage" />)}/>
            <Route path="/reg-user" element={(authenticatedUserRole === 1 || authenticatedUserRole === 2) ? (<RegUser userRole={authenticatedUserRole}/>) : (<Navigate replace to="/admin-dashboard" />)}/>
            <Route path="/modify-user" element={(authenticatedUserRole === 1) ? (<ModifyUser userRole={authenticatedUserRole}/>) : (<Navigate replace to="/admin-dashboard" />)}/>
            <Route path="/delete-user" element={(authenticatedUserRole === 1) ? (<DeleteUser userRole={authenticatedUserRole}/>) : (<Navigate replace to="/admin-dashboard" />)}/>
            <Route path="/mypage" element={authenticatedUserRole ? (<MyPage userRole={authenticatedUserRole} setAuthenticatedUserRole={setAuthenticatedUserRole}/>) : (<Navigate replace to="/login" />)}/>
            <Route path="/videos" element={authenticatedUserRole ? (<FileBrowser userRole={authenticatedUserRole} exercise={exercise} setExercise={setExercise}/>) : (<Navigate replace to="/login" />)}/>
            <Route path="/add-station" element={authenticatedUserRole ? (<AddStation userRole={authenticatedUserRole} exercise={exercise} setExercise={setExercise} />) : (<Navigate replace to="/login" />)}/>
            <Route path="/my-workouts" element={authenticatedUserRole ? (<MyWorkouts userRole={authenticatedUserRole} />) : (<Navigate replace to="/login" />)}/>
            <Route path="/createsession" element={authenticatedUserRole ? (<CreateSession userRole={authenticatedUserRole} />) : (<Navigate replace to="/login" />)}/>
            <Route path="/managesession" element={authenticatedUserRole ? (<ManageSession userRole={authenticatedUserRole} />) : (<Navigate replace to="/login" />)}/>
            <Route path="/usersettings" element={authenticatedUserRole ? (<UserSettings userRole={authenticatedUserRole} />) : (<Navigate replace to="/login" />)}/>
          </Routes>
          </div>
          { Boolean(authenticatedUserRole) && <NavBar/> }
        </Router>
      </div>
    </Fragment>
  );
}

export default App;

