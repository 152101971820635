import axios from "axios";
import { useState } from "react";
import Modal from "react-modal";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL


export function StationConfirmationModal({
  stationId,
  state,
  rerender,
  setRerender
}) {
  const [stationModalOpen, setStationModalOpen] = useState(false);

  function deleteStation(stationId) {
    axios.delete(`${API_URL}/training/delete-station-by-id`, {
      headers: {
        'token': localStorage.getItem("token")
      },
      params: {
        sessionId: state.session,
        stationId: stationId
      }
    }).then(response => {
      // TODO Send confirmation to user
      setRerender(!rerender);
    }).catch(error => {});
  }

  function confirmDeleteStation(stationId) {
    deleteStation(stationId);
    setStationModalOpen(false);
  }

  return <div>
            <button className="inline-block px-2.5 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-red-700" onClick={() => {
      setStationModalOpen(true);
    }}>X</button>
            <Modal className="fixed w-full h-full flex items-center justify-center" isOpen={stationModalOpen} ariaHideApp={false} onRequestClose={() => setStationModalOpen(false)}>
                <div className="max-w-sm bg-white rounded-lg p-4 shadow-lg">
                    <p className="text-center text-xl font-medium">Radera station</p>
                    <p className="text-center text-m p-4">Är du säker på att du vill radera stationen?</p>
                    <div className="flex justify-between p-4">
                        <button className="inline-block px-4 py-2 bg-red-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-red-400" onClick={() => confirmDeleteStation(stationId)}>Fortsätt</button>
                        <button className="inline-block px-4 py-2 bg-gray-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-gray-400" onClick={() => setStationModalOpen(false)}>Avbryt</button>
                    </div>
                </div>
            </Modal>
        </div>;
}
  