import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function AddStation({ exercise, setExercise }) {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    /*
    Exercise has the following structure:
    session: String,
    title: String,
    description: String,
    videoPath: String,
    */
    if (!state) { return }
    setExercise({
      ...exercise,
      session: state.session
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    axios.post(`${API_URL}/training/add-station`,
      { sessionId: exercise.session, title: exercise.title, description: exercise.description, videoPath: exercise.videoPath },
      {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token")
        }
      }).then((response) => {
        setErrorMessage("")

        const session = exercise.session;
        setExercise({
          session: "",
          title: "",
          description: "",
          videoPath: "Ingen video vald",
        });

        navigate("/managesession", {
          state: { session: session, error: errorMessage }
        });

      }).catch((error) => {
        setErrorMessage(error.response.data.message);
        return
      })
  }

  return (
    <>
      <div className="flex flex-col items-center mx-2">
        <div className="flex-col text-center items-center justify-center rounded bg-sky-100 mx-4 max-w-2xl my-4 w-full">
          <form id="addStation" className="flex flex-col py-6 w-auto items-center" onSubmit={(e) => handleSubmit(e)}>
            <h1 className="text-center text-lg font-bold">Skapa station</h1>
            <input value={exercise.title} required className="border rounded my-2 py-2 px-5 w-72 h-10" placeholder="Titel" onChange={(e) => setExercise({ ...exercise, title: e.target.value })}></input>
            <input value={exercise.description} className="border rounded my-2 py-2 px-5 w-72 h-10" placeholder="Beskrivning" onChange={(e) => setExercise({ ...exercise, description: e.target.value })}></input>
            <div className="flex flex-row items-center w-72">
              <input className="border rounded my-2 py-2 px-5 h-10 w-60" placeholder={exercise.videoPath} disabled />
              <button className="text-white font-extrabold border rounded bg-sky-400 py-1 px-3 w-14 h-10 hover:bg-sky-600" onClick={() => navigate("/videos", { state: { test: true } })}>+</button>
            </div>

            <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600" form="addStation">Skapa</button>
            {errorMessage && <div className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </>
  );
}

export default AddStation;