import { StationConfirmationModal } from './../utils/StationConfirmationModal';
import { useEffect, useState } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL


export function stripExtension(filename) {
    let parts = filename.split('.');
    if (parts.length > 1) {
        parts.pop(); // Remove the last element (the extension)
        return parts.join('.'); // Handle filenames with multiple periods
    }
    return filename; // Return the original filename if no extension is found
}

export function StationView({
  station,
  state,
  rerender,
  setRerender
}) {
  const [expanded, setExpanded] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
 
  function bucketRequest(queryPath) {
    let url = "";
    axios.get(`${API_URL}/bucket/presigned-url-file`, {
      headers: { 
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token")
      },
      params: {
        file: encodeURIComponent(`${queryPath.slice(1)}`)
      }
    })
    .then(response => {
      setVideoSrc(() => response.data);
    })
    .catch(err => {
      // TODO Handle the error, maybe give some feedback to the user
    });
  };

  useEffect(() => {
    if (videoSrc === "") {
      bucketRequest(station.videoUrl);
    }
  }, [expanded]);

  return <div className="border border-sky-800 rounded-md px-2 py-2 my-3" >
            <div className="flex justify-between">
                <button className="text-black font-medium truncate w-full h-8 text-left" onClick={() => setExpanded(!expanded)}>Titel: {stripExtension(station.title)}</button>
                <StationConfirmationModal stationId={station._id} state={state} rerender={rerender} setRerender={setRerender} />
            </div>

            {expanded && <div className="flex flex-col">
                <div>{station.description}</div>
                <video controls key={station.title}><source src={videoSrc} /></video>
              </div>}
        </div>;
}
  

