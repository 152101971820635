import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "react-modal";

function MyPage({ userRole, setAuthenticatedUserRole }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleLogout = () => {
    // Delete token from localstorage and redirect to loginpage
    localStorage.removeItem("token");
    window.location.replace("/login");
    setAuthenticatedUserRole(0);
  };

  return (
    <>
      <div className="flex flex-col items-center mx-2">
        <div className="max-w-2xl my-4 w-full">
          <Link to="/videos">
            <button className="flex justify-center w-full px-5 py-2 text-white bg-sky-500 rounded-lg hover:bg-sky-400">Videos</button>
          </Link>
          <Link to="/my-workouts">
            <button className="flex justify-center w-full px-5 py-2 my-4 text-white bg-sky-500 rounded-lg hover:bg-sky-400">Mina Träningspass</button>
          </Link>
          <Link to="/createsession">
            <button className="flex justify-center w-full px-5 py-2 my-4 text-white bg-sky-500 rounded-lg hover:bg-sky-400">Nytt pass</button>
          </Link>

          <Link to="/usersettings">
            <button className="flex justify-center w-full px-5 py-2 my-4 text-white bg-sky-500 rounded-lg hover:bg-sky-400">Inställningar</button>
          </Link>
          {
            userRole !== 3 &&
            <Link to="/admin-dashboard">
              <button className="flex justify-center w-full px-5 py-2 my-4 text-white bg-sky-500 rounded-lg hover:bg-sky-400">Administration</button>
            </Link>
          }
          <div>
            <button className="flex justify-center w-full px-5 py-2 my-4 text-white bg-red-500 rounded-lg hover:bg-red-400" onClick={() => setModalIsOpen(true)}>Logga ut</button>
            <Modal className="fixed w-full h-full flex items-center justify-center" isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
              <div className="max-w-sm bg-white rounded-lg p-4 shadow-lg">
                <p className="text-center text-xl font-medium">Logga ut</p>
                <p className="text-center text-m p-4">Är du säker på att du vill logga ut?</p>
                <div className="flex justify-between p-4">
                  <button className="inline-block px-4 py-2 bg-sky-800 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-sky-600" onClick={handleLogout}>Fortsätt</button>
                  <button className="inline-block px-4 py-2 bg-gray-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-gray-400" onClick={() => setModalIsOpen(false)}>Avbryt</button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPage;