import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function DeleteUser() {
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const { state } = useLocation();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const resetInput = () => {
            setPassword('');
        }

        try {
            const response = await axios.delete(`${API_URL}/users/delete-user`, {
                headers: {
                    "Content-Type": "application/json",
                    'token': localStorage.getItem("token")
                },
                data: {
                    userToDelete: user,
                    adminPassword: password
                }
            });
            setSuccessMsg('Användare raderad');
            setErrMsg('');
            resetInput();
            return response.data;

        } catch (error) {
            if (error.response) {
                setErrMsg(error.response.data.message);
            } else {
                setErrMsg('Något gick fel');
            }
            setSuccessMsg('');
            resetInput();
        }
    };

    useEffect(() => {
        if (state) {
            setUser(state.username);
        }
    }, []);

    return (
        <>
            <div className="flex flex-col items-center mx-2">
                <div className="flex flex-col text-center items-center rounded bg-sky-100 mx-4 max-w-2xl my-4 w-full">
                    <form onSubmit={handleSubmit} className="pt-4 pb-4">
                        <h1 className="text-center text-lg font-bold">Radera användare</h1>

                        <div>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={(e) => setUser(e.target.value)}
                                type="text"
                                value={user}
                                placeholder='Användare'
                                autoComplete="off"
                                required />
                        </div>

                        <div>
                            <p className="text-center">Bekräfta genom att ange ditt lösenord</p>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                value={password}
                                placeholder='Lösenord'
                                autoComplete="off"
                                required />
                        </div>
                        <button className="flex justify-center px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600">Fortsätt</button>

                        {errMsg && <p className="border rounded bg-red-100 border-red-400 flex justify-center py-1 px-5">{errMsg}</p>}
                        {successMsg && <p className="border rounded bg-green-100 border-green-400 flex justify-center py-1 px-5">{successMsg}</p>}
                    </form>
                    <button className="w-72 px-5 py-2 my-3 text-white border-2 border-sky-400 bg-sky-300 rounded-lg hover:bg-sky-600 text-center" onClick={() => navigate(-1)}>Tillbaka</button>
                </div>
            </div>

        </>
    );
}

export default DeleteUser;