import React, { useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL;

function RegUser({ userRole }) {
    const [user, setUser] = useState({
        username: '',
        password: '',
        role: '',
    });

    const [confirm, setConfirm] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const HandleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user.username === '' || user.password === '' || confirm === '') {
            document.getElementById('selectRole').value = 'Default';
            document.getElementById('username').value = '';
            document.getElementById('password').value = '';
            document.getElementById('confirm').value = '';
            setUser({ username: '', password: '', role: '' });
            setConfirm('');
            return;
        } else if (user.password !== confirm) {
            setErrMsg('Lösenorden matchar inte');
            setSuccessMsg('');
            document.getElementById('password').value = '';
            document.getElementById('confirm').value = '';
            setUser({ ...user, password: '' });
            setConfirm('');
            return;
        } else if (user.role === '' || user.role === 'Default') {
            setErrMsg('Välj en användarroll');
            return;
        } else {
        }

        try {
            const response = await axios.post(`${API_URL}/users/add-user`, user, {
                headers: {
                    token: localStorage.token,
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.message) {
                setSuccessMsg(response.data.message);
                setErrMsg('');
            }
        } catch (error) {
            setErrMsg(error.response.data.message);
            setSuccessMsg('');
        }

        setUser({ username: '', password: '', role: '' });
        setConfirm('');
        document.getElementById('selectRole').value = 'Default';
        document.getElementById('username').value = '';
        document.getElementById('password').value = '';
        document.getElementById('confirm').value = '';
    };


    return (
        <div className="flex flex-col items-center mx-2">
            <div className="flex flex-col text-center items-center rounded bg-sky-100 mx-4 max-w-2xl my-4 w-full">
                <form onSubmit={handleSubmit} className="pt-6 pb-6">
                    <h1 className="text-center text-lg font-bold">Registrera ny användare</h1>
                    <div>
                        <input id="username"
                            className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                            onChange={HandleChange}
                            name="username"
                            type="text"
                            placeholder='Användarnamn'
                            autoComplete="off"
                            required />
                    </div>

                    <div>
                        <input id="password"
                            className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                            onChange={HandleChange}
                            name="password"
                            type="password"
                            placeholder='Lösenord'
                            autoComplete="off"
                            required />
                    </div>

                    <div>
                        <input id="confirm"
                            className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                            onChange={(e) => setConfirm(e.target.value)}
                            name="confirm"
                            type="password"
                            placeholder='Bekräfta lösenord'
                            autoComplete="off"
                            required />
                    </div>
                    {(userRole === 1) &&
                        <div>
                            <div>
                                <select id="selectRole"
                                    name="role"
                                    onChange={HandleChange}
                                    className="text-black border rounded py-2 px-5 my-2 w-72">
                                    <option value="Default">Användarroll</option>
                                    <option value="3">User</option>
                                    <option value="2">Web-admin</option>
                                    <option value="1">Admin</option>
                                </select>
                            </div>
                            <div>
                                <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600" type="submit">Lägg till</button>
                            </div>
                        </div>}

                    {(userRole === 2) &&
                        <div>
                            <div>
                                <select id="selectRole"
                                    name="role"
                                    onChange={HandleChange}
                                    className="text-black border rounded py-2 px-5 my-2 w-72">
                                    <option value="Default">Användarroll</option>
                                    <option value="3">User</option>
                                </select>
                            </div>
                            <div>
                                <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600" type="submit">Lägg till</button>
                            </div>
                        </div>}

                    {errMsg && <div className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{errMsg}</div>}
                    {successMsg && <div className="border rounded bg-green-100 border-green-400 flex justify-center py-1 px-5 w-72 mx-auto">{successMsg}</div>}
                </form>
            </div>
        </div>
    );
}
export default RegUser;