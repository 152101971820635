import { useRef, useState } from "react";
const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function Login({ setAuth }) {

  const errRef = useRef();

  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const body = user;
      const response = await fetch(`${API_URL}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });

      const parseRes = await response.json()

      // Valid token gives access
      if (parseRes.jwtToken) {
        localStorage.setItem("token", parseRes.jwtToken);
        setAuth(parseRes.role);
        setErrMsg("");
      } else {
        // Not valid token prints error
        setErrMsg("Felaktiga uppgifter");
      }
    }
    catch (err) {
      setErrMsg('Inloggning misslyckades');
      errRef.current.focus();
    }
  }

  return (
    <>
      <div className="flex flex-col text-center items-center justify-center mx-2">
        <div className="rounded bg-sky-500 mx-4 max-w-2xl my-4 w-full">
          <form onSubmit={handleSubmit} className="pt-6 pb-6 w-auto">
            <div className="py-2">
              <input
                autoComplete="off"
                type="text"
                onChange={(e) => setUser({ ...user, username: e.target.value })} // Copies all properties in the object and updates username
                className="border rounded py-2 px-5 w-72"
                placeholder="Användarnamn"
                required />
            </div>

            <div className="py-2">
              <input
                type="password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                className="border rounded py-2 px-5 w-72"
                placeholder="Lösenord"
                required />
            </div>

            <div className="py-2">
              <button className="px-5 py-2 text-white bg-sky-800 rounded hover:bg-sky-600 w-72">Logga in</button>
            </div>
            {errMsg && <p className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{errMsg}</p>}
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;