import {React, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

const Banner = ({loggedIn}) => { 
  const token = localStorage.getItem('token');
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  // Fetch user information from localstorage
  useEffect(() => {
    if (!token) {
      return
    };

    // Extract username from JWT for display in banner
    const payload = JSON.parse(atob(token.split('.')[1]));
    setUsername(payload.username);
  }, [token]);

  return (

    <header className="flex justify-between text-white bg-sky-500">
      <div className="text-center text-m font-extrabold py-5 px-5">
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo_vast.png`}
          alt=''
          width="120"
          height="48" onClick={() => navigate("/mypage")}/>
      </div>
      <div className="text-m py-5 px-5">
        {loggedIn ? (
        <><p className="font-bold">Inloggad som</p>
        <p className="text-right">{username}</p></>
        ) : (
        <p>{null}</p>
        )}

      </div>
    </header>
)};

export default Banner;