import { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

// TODO Refactor error & success messages into one combined useState?
function ModifyUser() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [passwordSuccessMsg, setPasswordSuccessMsg] = useState('');
    const [roleErrMsg, setRoleErrMsg] = useState('');
    const [roleSuccessMsg, setRoleSuccessMsg] = useState('');
    const [submitPasswordValues, setSubmitPasswordValues] = useState({
        username: '',
        newPassword: '',
        confirmNewPassword: '',
        password: '',
    });
    const [submitRoleValues, setSubmitRoleValues] = useState({
        username: '',
        role: '',
        password: ''
    })

    // Function to handle values from userpassword form
    const handlePasswordForm = (event) => {
        const { name, value } = event.target;
        setSubmitPasswordValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Function to handle values from userrole form
    const handleRoleForm = (event) => {
        const { name, value } = event.target;
        setSubmitRoleValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Clear fields at submit
    const resetInput = () => {
        setSubmitPasswordValues({
            newPassword: '',
            confirmNewPassword: '',
            password: '',
        })
        setSubmitRoleValues({
            role: '',
            password: ''
        })
    };

    const handleSubmitPassword = async (e) => {
        e.preventDefault();
        const { username, newPassword, confirmNewPassword, password } = submitPasswordValues;

        try {
            // Check if new and confirmed password match
            if (!newPassword || newPassword !== confirmNewPassword) {
                setPasswordErrMsg('Ett eller flera lösenord är felaktiga')
                setPasswordSuccessMsg('');
                return;
            }
            const response = await axios.patch(`${API_URL}/users/admin-change-userpassword`, {
                username: username,
                newPassword: newPassword,
                adminPassword: password
            }, {
                headers: {
                    'token': localStorage.getItem("token")
                }
            });
            if (response.data.message) {
                setPasswordSuccessMsg(response.data.message);
                setPasswordErrMsg('');
            }
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setPasswordErrMsg(error.response.data.message);
            } else {
                setPasswordErrMsg('Ett fel uppstod när lösenordet skulle ändras');
            }
            setPasswordSuccessMsg('');
        };
        resetInput();
    };

    const handleSubmitRole = async (e) => {
        e.preventDefault();
        const { username, role, password } = submitRoleValues;

        try {
            const response = await axios.patch(`${API_URL}/users/admin-change-userrole`, {
                username: username,
                userRole: role,
                adminPassword: password
            }, {
                headers: {
                    'token': localStorage.getItem("token")
                }
            });
            if (response.data.message) {
                setRoleSuccessMsg(response.data.message);
                setRoleErrMsg('');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setRoleErrMsg(error.response.data.message);
            } else {
                setRoleErrMsg('Ett fel uppstod när rollen skulle ändras');
            }
            setRoleSuccessMsg('');
        };
        resetInput();
    };

    useEffect(() => {
        if (state) {
            setSubmitPasswordValues({ ...submitPasswordValues, username: state.username });
            setSubmitRoleValues({ ...submitRoleValues, username: state.username });
        }
    }, []);


    return (
        <div className="flex flex-col items-center mx-2">
            <div className="flex flex-col text-center items-center rounded bg-sky-100 mx-4 max-w-2xl my-4 w-full">
                <div className="flex justify-center">
                    <form onSubmit={handleSubmitPassword} className="pt-4 pb-4">
                        <h1 className="text-center text-lg font-bold">Redigera användarlösenord</h1>
                        <div>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={handlePasswordForm}
                                type="text"
                                name="username"
                                value={submitPasswordValues.username}
                                placeholder='Användare'
                                autoComplete="off"
                                required />
                        </div>

                        <div>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={handlePasswordForm}
                                type="password"
                                name="newPassword"
                                value={submitPasswordValues.newPassword}
                                placeholder='Nytt lösenord'
                                autoComplete="off"
                                required />
                        </div>

                        <div>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={handlePasswordForm}
                                type="password"
                                name="confirmNewPassword"
                                value={submitPasswordValues.confirmNewPassword}
                                placeholder='Bekräfta nytt lösenord'
                                autoComplete="off"
                                required />
                        </div>

                        <div>
                            <p className="text-center">Bekräfta genom att ange ditt lösenord</p>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={handlePasswordForm}
                                type="password"
                                name="password"
                                value={submitPasswordValues.password}
                                placeholder='Lösenord'
                                autoComplete="off"
                                required />
                        </div>
                        <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600">Fortsätt</button>

                        {passwordErrMsg && <p className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{passwordErrMsg}</p>}
                        {passwordSuccessMsg && <p className="border rounded bg-green-100 border-green-400 py-1 px-5 w-72 mx-auto">{passwordSuccessMsg}</p>}
                    </form>
                </div>

                <div className="flex justify-center">
                    <form onSubmit={handleSubmitRole} className="pt-4 pb-4">
                        <h1 className="text-center text-lg font-bold">Redigera användarroll</h1>
                        <div>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={handleRoleForm}
                                type="text"
                                name="username"
                                value={submitRoleValues.username}
                                placeholder='Användare'
                                autoComplete="off"
                                required />
                        </div>

                        <div>
                            <select className="text-black border rounded py-2 px-5 my-2 w-72"
                                onChange={handleRoleForm}
                                type="select"
                                name="role"
                                value={submitRoleValues.role}
                                required>
                                <option value="Default">Användarroll</option>
                                <option value="3">Standard Användare</option>
                                <option value="2">Site Admin</option>
                                <option value="1">Admin</option>
                            </select>
                        </div>

                        <div>
                            <p className="text-center">Bekräfta genom att ange ditt lösenord</p>
                            <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                                onChange={handleRoleForm}
                                type="password"
                                name="password"
                                value={submitRoleValues.password}
                                placeholder='Lösenord'
                                autoComplete="off"
                                required />
                        </div>
                        <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600">Fortsätt</button>

                        {roleErrMsg && <p className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{roleErrMsg}</p>}
                        {roleSuccessMsg && <p className="border rounded bg-green-100 border-green-400 py-1 px-5 w-72 mx-auto">{roleSuccessMsg}</p>}
                    </form>
                </div>
                <button className="flex justify-center w-72 px-5 py-2 my-3 text-white border-2 border-sky-400 bg-sky-300 rounded-lg hover:bg-sky-600" onClick={() => navigate(-1)}>Tillbaka</button>
            </div>
        </div>
    );
};

export default ModifyUser;