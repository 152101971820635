import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function stripExtension(filename) {
    let parts = filename.split('.');
    if (parts.length > 1) {
        parts.pop(); // Remove the last element (the extension)
        return parts.join('.'); // Handle filenames with multiple periods
    }
    return filename; // Return the original filename if no extension is found
}

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

const Collapse = (props) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  const handleClick = (title) => {
    props.setExercise({
      ...props.exercise,
      videoPath: `${props.path}/${title}`
    })
    navigate(-1);
  }
  
  useEffect(() => {
    if (open && url === "") {
      axios.get(`${API_URL}/bucket/presigned-url-file`, {
        headers: { 
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token")
        },
        params: {
          file: encodeURIComponent(`${props.path.slice(1)}/${props.title}`)
        }
      })
      .then(response => {
        setUrl(() => response.data);
      })
      .catch(err => {
        // TODO Handle the error, maybe give some feedback to the user
      });
    }
  },[open]);
  
  return (
    <div className="border border-sky-800 rounded-md px-5 py-2 my-4">
      <div className="flex flex-row justify-between items-center" >
        <div className="w-full h-8 text-left" onClick={() => setOpen(!open)}>{ stripExtension(props.title) }</div>
        {props.state && <button className="inline-block w-7 bg-sky-600 text-white font-medium text-2xl leading-tight rounded shadow-md hover:bg-sky-700" onClick={() => handleClick(stripExtension(props.title))}>+</button>}
        </div>        
      { (open && url) && 
      <div className="flex justify-center py-2">
        <video controls key={props.title}><source src={url} /></video>
      </div> }
    </div>
  );
}
  
export default Collapse;

