import Collapse from "../components/Collapse";
import { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function stripExtension(filename) {
    let parts = filename.split('.');
    if (parts.length > 1) {
        parts.pop(); // Remove the last element (the extension)
        return parts.join('.'); // Handle filenames with multiple periods
    }
    return filename; // Return the original filename if no extension is found
}

function FileBrowser({ exercise, setExercise }) {
  const [bucketPaths, setBucketPaths] = useState([]);
  const [currentPath, setCurrentPath] = useState({
    path: "",
    subfolders: [],
    depth: 0,
    videos: []
  });
  const { state } = useLocation();
  const navigate = useNavigate();

  async function getFilepaths() {
    try {
      const response = await axios.get(`${API_URL}/structure/structure`, {
          headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token")
          }
      }).then((response) => {
        setBucketPaths(bucketPaths => response.data);
      });
        
    } catch (error) {
      console.error('Error fetching filepaths:', error);
    }
  };
  

  function bucketRequest(queryPath) {
    return new Promise((resolve, reject) => {
      var videos = [];
      axios.get(`${API_URL}/bucket/list-folder`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token")
        },
        params: {
          path: encodeURIComponent(queryPath)
        }
      })
        .then(response => {
          videos = response.data;
          resolve(videos);
        })
        .catch(err => {
          // TODO Handle the error, maybe give some feedback to the user
          console.log(err);
          reject(err);
        });
    });
  };

  async function handleClickForward(e) {
    // Update current folder
    let newPath = `${currentPath.path}/${e.target.id}`;
    let depth = currentPath.depth + 1;
    let videos = [];
    let pathEnd = bucketPaths.includes(newPath);
    if (newPath !== "" && pathEnd) {
      let queryPath = newPath.slice(1);
      // Fetch the array from the API endpoint
      videos = await bucketRequest(queryPath);
      // Confirm file format
      videos = videos.map(video => {
        if (video.includes(".mp4")) {
          return video;
        }
      });
    }
    // Get subfolders for the new path. Limit to account for the depth by checking if we are already at a complete path
    let subfolders = !pathEnd ? Array.from(new Set(bucketPaths.filter(obj => obj.startsWith(newPath)).map(obj => obj.split('/')[depth + 1]))) : [];
    setCurrentPath(currentPath => ({
      path: newPath,
      subfolders: subfolders,
      depth: depth,
      videos: videos
    }));
  }

  function handleClickBack() {
    // Prevent out of bound by returning early if at root
    if (currentPath.path === "") return
    let newPath = currentPath.path.split('/').slice(0, -1).join('/');
    let depth = currentPath.depth - 1;
    let subfolders = Array.from(new Set(bucketPaths.filter(obj => obj.startsWith(newPath)).map(obj => obj.split('/')[depth + 1])));

    setCurrentPath(currentPath => ({
      path: newPath,
      subfolders: subfolders,
      depth: depth,
      videos: []
    }));
  }

  useEffect(() => {
    getFilepaths();
    // Filter the path to find each subfolder
    let subfolders = Array.from(new Set(bucketPaths.map(obj => obj.split('/')[currentPath.depth + 1])));
    setCurrentPath(currentPath => ({
      ...currentPath,
      subfolders: subfolders
    }));
  }, [bucketPaths.length]);

  return (
    <>
      <div className="flex flex-col items-center mx-2">
        <div className="max-w-2xl my-1 w-full">
          {currentPath.subfolders.length !== 0 && currentPath.subfolders.map(folder => {
            return <div id={folder} key={folder} user-select="none" className="flex justify-center w-full px-5 py-2 my-3 text-white bg-sky-500 rounded-lg hover:bg-sky-400" onClick={e => handleClickForward(e)}>{folder}</div>;
          })}
          {currentPath.videos.length !== 0 && currentPath.videos.map(video => {
            return <Collapse key={video} title={video} path={currentPath.path} exercise={exercise} setExercise={setExercise} state={state} />
          })
          }
          {currentPath.depth !== 0 ?
            <div className="mt-8">
              <button className="flex justify-center w-full px-5 py-2 my-4 text-white border-2 border-sky-400 bg-sky-800 rounded-lg hover:bg-sky-600" onClick={e => handleClickBack(e)}>Föregående mapp</button>
            </div> : null}
          <button className="flex justify-center w-full px-5 py-2 my-4 text-white border-2 border-red-400 bg-red-700 rounded-lg hover:bg-red-600" onClick={() => navigate(-1)}>Tillbaka</button>
        </div>
      </div>
    </>
  );
}

export default FileBrowser;
