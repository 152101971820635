import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function CreateSession() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [session, setSession] = useState({
        title: "",
        description: ""
    });

    function handleSubmit(e) {
        e.preventDefault();
        axios.post(`${API_URL}/training/add-session`,
            { title: session.title, description: session.description },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem("token")
                }
            }).then((response) => {
                navigate("/add-station", {
                    state: { session: response.data._id }
                });
            }).catch((error) => {
                setErrorMessage(error.response.data.message);
                return
            });
    }

    return (
        <>
            <div className="flex flex-col items-center mx-2">
                <div className="flex-col text-center items-center justify-center rounded bg-sky-100 mx-4 max-w-2xl my-4 w-full">
                    <form id="session-form" className="flex flex-col py-6 w-auto items-center" onSubmit={(e) => handleSubmit(e)}>
                        <h1 className="text-center text-lg font-bold">Skapa nytt pass</h1>
                        <input value={session.title} required className="border rounded my-2 py-2 px-5 w-72 h-10" placeholder="Titel" onChange={(e) => setSession({ ...session, title: e.target.value })} ></input>
                        <input value={session.description} className="border rounded my-2 py-2 px-5 w-72 h-10" placeholder="Beskrivning" onChange={(e) => setSession({ ...session, description: e.target.value })} ></input>
                        <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600" form="session-form">Skapa</button>
                        {errorMessage && <div className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{errorMessage}</div>}
                    </form>
                </div>
            </div>
        </>
    );
}

export default CreateSession;