import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function AdminDashboard({userRole}){
    const [updateMessage, setUpdateMessage] = useState({
        msg: "",
        status: false
    });
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    function onClick(path, username) {
        navigate(path, {state: { username: username}});
    }

    function updateDatabase() {
        axios.post(`${API_URL}/bucket/build-structure`, {
            headers: { 
              'Content-Type': 'application/json',
              'token': localStorage.getItem("token")
            }
          })
          .then(response => {
            setUpdateMessage(prevState => ({
                ...prevState, 
                msg: "Databasen är uppdaterad.",
                status: true}
            ));   
          })
          .catch(err => {
            setUpdateMessage(prevState => ({
                ...prevState, 
                msg: "Uppdateringen misslyckades.",
                status: false}
            ));    
          });
    } 

    useEffect(() => {
        axios.get(`${API_URL}/users/list-users`, {
            headers: { 
              'Content-Type': 'application/json',
              'token': localStorage.getItem("token")
            }
          })
          .then(response => {
            const currentUser = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).username;
            // Remove the current user so that an admin cant delete their own account
            var activeUsers = response.data.userData.filter(user => user.username !== currentUser);
            setUsers(() => activeUsers);        
          })
          .catch(err => {
            // TODO Handle the error, maybe give some feedback to the user
          });
    }, []);

    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-col items-center">
                    <button className="w-full py-2 mt-6 text-white bg-sky-500 rounded-lg hover:bg-sky-400 max-w-2xl" onClick={() => updateDatabase()}>Uppdatera databas</button>
                    {updateMessage.msg && <p className={`w-full text-center border rounded mt-2 py-1 px-5 max-w-2xl ${updateMessage.status ? "bg-green-100 border-green-400" : "bg-red-100 border-red-400"}`}>{updateMessage.msg}</p>}
                </div>

                <Link to="/reg-user">
                    <div className="flex justify-center">
                        <button className="flex justify-center w-full py-2 my-4 text-white bg-sky-500 rounded-lg hover:bg-sky-400 max-w-2xl">Lägg till ny användare</button>
                    </div>
                </Link>
            </div>

            <div className="flex flex-col items-center mx-2">            
                <div className="max-w-2xl my-4 w-full">
                    <div className="text-center text-lg font-bold">Användare</div>
                    {
                        users.length > 0 && users.map(user => {
                            return (
                                <div key={user._id} className="h-12 flex flex-row items-center justify-between bg-sky-100 w-full my-3 border border-sky-300 rounded-md">
                                    <div className="flex flex-row">
                                        <div className="text-left mx-4 font-bold w-20">{user.username}</div>
                                        <div className="text-left mx-4 font">{["Admin", "Web-admin", "User"][user.role-1]}</div>
                                    </div>
                                    {userRole === 1 && <div className="inline-flex">
                                        <button className="inline-block px-2.5 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-sky-700 m-2" onClick={() => onClick("/modify-user", user.username)}>Ändra</button>
                                        <button className="inline-block px-2.5 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-red-700 m-2" onClick={() => onClick("/delete-user", user.username)}>X</button>
                                    </div>}
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
        </>
    );
}

export default AdminDashboard;
