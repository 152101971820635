import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

export function SessionConfirmationModal({
  state
}) {
  const [workoutModalOpen, setWorkoutModalOpen] = useState(false);
  const navigate = useNavigate();

  function deleteSession() {
    axios.delete(`${API_URL}/training/delete-session-by-id`, {
      headers: {
        'token': localStorage.getItem("token")
      },
      params: {
        sessionId: state.session
      }
    }).then(response => {
      // TODO Send confirmation to user
      navigate("/my-workouts");
    }).catch(error => {});
  }

  return <div>
            <button className="inline-block px-2.5 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-red-700" onClick={() => setWorkoutModalOpen(true)}>Radera pass</button>
            <Modal className="fixed w-full h-full flex items-center justify-center" isOpen={workoutModalOpen} ariaHideApp={false} onRequestClose={() => setWorkoutModalOpen(false)}>
                <div className="max-w-sm bg-white rounded-lg p-4 shadow-lg">
                    <p className="text-center text-xl font-medium">Radera pass</p>
                    <p className="text-center text-m p-4">Är du säker på att du vill radera passet?</p>
                    <div className="flex justify-between p-4">
                        <button className="inline-block px-4 py-2 bg-red-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-red-400" onClick={() => deleteSession()}>Fortsätt</button>
                        <button className="inline-block px-4 py-2 bg-gray-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-gray-400" onClick={() => setWorkoutModalOpen(false)}>Avbryt</button>
                    </div>
                </div>
            </Modal>
        </div>;
}
  