import React from 'react';
import {Link} from "react-router-dom";

const NavBar = () => {
  return (
    <footer className="bg-gray-800 text-white border-t border-gray-200">
      <div className="container mx-auto flex items-center justify-between py-3">
        <div className="flex-1 text-center">
          <Link to="/mypage">
          <button className="px-4 py-2 text-gray-400 font-medium">Meny</button>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default NavBar;