import { useState } from "react";
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function ChangePassword() {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const resetInput = () => {
        setPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if new password and confirm new password match
        // TODO Should this be done in the API instead?
        if (newPassword !== confirmNewPassword) {
            setErrMsg('Ett eller flera lösenord är felaktiga')
            setSuccessMsg('');
            resetInput();
            return;
        }

        try {
            const response = await axios.patch(`${API_URL}/users/change-password`, {
                currentPassword: password,
                newPassword: newPassword
            }, {
                headers: {
                    'token': localStorage.getItem("token")
                }
            });
            if (response.data.message) {
                setSuccessMsg(response.data.message);
                setErrMsg('');
            }
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrMsg(error.response.data.message);
            } else {
                setErrMsg('Ett fel uppstod när lösenordet skulle ändras');
            }
            setSuccessMsg('');
        }
        resetInput();
    };

    return (
        <div className="flex flex-col items-center mx-2">
            <div className="flex-col text-center items-center justify-center rounded bg-sky-100 mx-4 max-w-2xl my-4 w-full">
                <form onSubmit={handleSubmit} className="pt-4 pb-4">
                    <h1 className="text-center text-lg font-bold">Ändra lösenord</h1>
                    <div>
                        <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            value={password}
                            placeholder='Nuvarande lösenord'
                            autoComplete="off"
                            required />
                    </div>

                    <div>
                        <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                            onChange={(e) => setNewPassword(e.target.value)}
                            type="password"
                            value={newPassword}
                            placeholder='Nytt lösenord'
                            autoComplete="off"
                            required />
                    </div>

                    <div>
                        <input className="border rounded border-sky-400 py-2 px-5 my-2 w-72"
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            type="password"
                            value={confirmNewPassword}
                            placeholder='Bekräfta nytt lösenord'
                            autoComplete="off"
                            required />
                    </div>
                    <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600">Fortsätt</button>

                    {errMsg && <p className="border rounded bg-red-100 border-red-400 py-1 px-5 w-72 mx-auto">{errMsg}</p>}
                    {successMsg && <p className="border rounded bg-green-100 border-green-400 py-1 px-5 w-72 mx-auto">{successMsg}</p>}
                </form>
                <div>
                </div>
            </div>
        </div>

    );
}
export default ChangePassword;