import { StationView } from './../utils/StationView';
import { SessionConfirmationModal } from './../utils/SessionConfirmationModal';
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function SessionsViewer() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [session, setSession] = useState({
        station: []
    });
    // TODO Do something else here. This rerender is just used to toggle the refresh when deleting a station. It is stupid af
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        // Display error if no data is passed by state
        if (!state.session) {
            setErrorMessage("Ingen data tillgänglig");
            return
        }
        if (state.error) {
            setErrorMessage(() => state.error)
        }
        axios.get(`${API_URL}/training/session-by-id`,
            {
                headers: {
                    'token': localStorage.getItem("token")
                },
                params: {
                    sessionId: state.session
                }
            }).then((response) => {
                setSession(response.data);
                setErrorMessage("");
            }).catch((error) => {
                console.log(error)
                setErrorMessage("Fel vid hämntning av data");
            });
    }, [rerender]);

    return (
        <>
            <div className="flex flex-col items-center mx-2">
                <div className="rounded bg-sky-100 mx-4 px-2 py-2 max-w-2xl my-4 w-full">
                    {session && (
                        <div>
                            <div className="flex justify-between">
                                <div className="font-medium truncate">Titel: {session.title}</div>
                            </div>
                            <div className="">Beskrivning: {session.description}</div>
                        </div>
                    )}
                    {session && session.station.map((s, index) => (
                        <div key={index}>
                            <StationView station={s} state={state} rerender={rerender} setRerender={setRerender} />
                        </div>
                    ))}
                    {errorMessage && <div className="border rounded bg-red-100 border-red-400 text-center py-1 px-5 w-72 my-4 mx-auto">{errorMessage}</div>}
                    {session && !errorMessage && <div className="flex justify-between py-2">
                        <button className="inline-block px-4 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-sky-600" onClick={() => navigate("/add-station", { state: { session: session._id } })}>Ny station</button>
                        <SessionConfirmationModal state={state} />
                    </div>}
                </div>
            </div>
        </>
    )
};

export default SessionsViewer;
