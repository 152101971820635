import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL

function MyWorkouts() {
  const [sessions, setSessions] = useState([]);
  const navigation = useNavigate();

  function fetchSessions() {
    axios.get(`${API_URL}/training/owner-sessions`, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token")
      }
    }).then((response) => {
      setSessions(response.data)
    }).catch((error) => {
      // TODO Handle error
      console.log(error)
    });
  }

  function handleClick(e) {
    navigation("/managesession", {
      state: { session: e.target.id }
    })
  }

  useEffect(() => {
    fetchSessions();
  }, [sessions.length])

  return (
    <>
      <div className="flex flex-col items-center mx-2">
        <div className="flex-col text-center rounded bg-sky-100 mx-4 py-4 max-w-2xl my-4 w-full">
          <p className="text-center text-lg font-bold">Mina träningspass</p>
          <div className="w-72 mx-auto">
            {
              (sessions.length !== 0) && sessions.map(session => {
                return (
                  <div id={session._id} key={session._id} className="flex flex-col items-center text-center text-white bg-sky-500 rounded-lg hover:bg-sky-400 my-4 " onClick={(e) => handleClick(e)}>
                    <div className="text-white font-bold my-2 inline-block w-[200px] truncate">{session.title}</div>
                    <div className="text-white my-2 inline-block w-[200px] truncate">{session.description}</div>
                  </div>

                )
              })
            }</div>
          <Link to={{ pathname: "/createsession" }} >
            <div>
              <button className="px-5 py-2 my-3 w-72 text-white bg-sky-800 rounded hover:bg-sky-600">Skapa nytt pass</button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default MyWorkouts;
